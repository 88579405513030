import React from "react";
import { Container, Image, Row, Collapse, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import api from "../utils/api";
import { getProfilePhoto } from "../utils/helper";
import { numberFormatBRL } from "./InvestmentId";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';
import { t } from "i18next";

const SideBarItem = React.forwardRef(
  ({ activeClassName, activeStyle, ...props }, ref) => {
    if (!props.page) {
      return (
        <div
          ref={ref}
          className="text-mid"
          style={{
            backgroundColor:
              props.selected === props.page && props.selected !== undefined
                ? "#CEBA85"
                : props.bg
                  ? "#F1F1F1"
                  : "#E2E2E2",
            width: "100%",
            height: 26,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            color: "black",
            cursor: "pointer",
          }}
          onClick={props.onClick}
          {...props}
        >
          {props.children}
        </div>
      );
    }
    return (
      <NavLink
        ref={ref}
        to={"/panel/" + props.page}
        className="text-mid text-dark"
        style={({ isActive }) => ({
          backgroundColor: isActive
            ? "#CEBA85"
            : props.bg
              ? "#F1F1F1"
              : "#E2E2E2",
          width: "100%",
          height: 26,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          color: isActive ? "#000000 !important" : "#343a40 !important",
          cursor: "pointer",
          textDecoration: "none",
        })}
        onClick={props.onClick}
        {...props}
      >
        {props.children}
      </NavLink>
    );
  }
)

// const formatBRL = (value) => {
//   // remove symbol
//   let stringVal = value;
//   stringVal = new Intl.NumberFormat("pt-BR", {
//     style: "currency",
//     currency: "BRL",
//   }).format(stringVal);
//   stringVal = stringVal.replace("R$", "").trim();

//   return stringVal;
// };




class SideBar extends React.Component {

  constructor(props) {
    super(props);

    let state = {
      menu: [],
      name: "carregando..",
      type: "carregando..",
      balance_brl: 0,
      reloading: false,
      reloadingInvestments: false
    };
    this.state = state;
  }

  static contextType = AppContext;


  // export const this.userPages = (userInfoKYC) => {
  //   const completeRegistration =
  //     (userInfoKYC?.kycStatus !== "none")
  //       ? [
  //           {
  //             name: "Meus investimentos",
  //             pages: [
  //               { page: "trade", name: "Investimentos" },
  //               { page: "trade_hist", name: "Histórico de Investimentos" },
  //               { page: "doc_history", name: "Histórico < 2023" },
  //             ],
  //           },
  //           {
  //             name: "Depósitos / Saques",
  //             pages: [
  //               { page: "deposits", name: "Depósitos" },
  //               { page: "withdraw", name: "Saques" },
  //             ],
  //           },
  //           {
  //             name: "Orderbook",
  //             pages: [
  //               { page: "orderbook", name: "Compra e venda" },
  //               { page: "tx_history", name: "Histórico" },
  //             ],
  //           },
  //         ]
  //       : [];

  userPages = (userInfoKYC, hasInvestement, hasReferal) => {
    let completeRegistration = [];
    console.log(this.context.referralCode);
    if (userInfoKYC?.kycStatus !== "none" && hasInvestement && hasReferal) {
      completeRegistration = [
        {
          name: this.props.t("myinvestments"),
          pages: [
            { page: "trade", name: this.props.t("investments") },
            { page: "trade_hist", name: this.props.t("investmentshistory") },
            { page: "doc_history", name: this.props.t("pasthistoryinvestments") /*"Histórico < 2023" */ },
          ],
        },
        {
          name: this.props.t("referalProgram"),
          pages: [
            { page: "referral", name: this.props.t("myAssocietes") },
          ],
        },
        {
          name: this.props.t("depositswithdraws") /* "Depósitos / Saques" */,
          pages: [
            { page: "deposits", name: this.props.t("deposits") },
            { page: "withdraw", name: this.props.t("withdraws") },
          ],
        },
        {
          name: "Orderbook",
          pages: [
            { page: "orderbook", name: this.props.t("buysellm") },
            { page: "tx_history", name: this.props.t("historic") },
          ],
        },
      ]
    } else if (userInfoKYC?.kycStatus !== "none" && !hasInvestement && hasReferal) {
      completeRegistration = [
        {
          name: this.props.t("referalProgram"),
          pages: [
            { page: "referral", name: this.props.t("myAssocietes") },
          ],
        },
        {
          name: this.props.t("depositswithdraws") /* "Depósitos / Saques" */,
          pages: [
            { page: "deposits", name: this.props.t("deposits") },
            { page: "withdraw", name: this.props.t("withdraws") },
          ],
        },
        {
          name: "Orderbook",
          pages: [
            { page: "orderbook", name: this.props.t("buysellm") },
            { page: "tx_history", name: this.props.t("historic") },
          ],
        },
      ]
    } else if (userInfoKYC?.kycStatus !== "none" && hasInvestement && !hasReferal) {
      completeRegistration = [
        {
          name: this.props.t("myinvestments"),
          pages: [
            { page: "trade", name: this.props.t("investments") },
            { page: "trade_hist", name: this.props.t("investmentshistory") },
            { page: "doc_history", name: this.props.t("pasthistoryinvestments") /*"Histórico < 2023" */ },
          ],
        },
        {
          name: this.props.t("depositswithdraws") /* "Depósitos / Saques" */,
          pages: [
            { page: "deposits", name: this.props.t("deposits") },
            { page: "withdraw", name: this.props.t("withdraws") },
          ],
        },
        {
          name: "Orderbook",
          pages: [
            { page: "orderbook", name: this.props.t("buysellm") },
            { page: "tx_history", name: this.props.t("historic") },
          ],
        },
      ]
    } 
    else if (userInfoKYC?.kycStatus !== "none" && !hasInvestement && !hasReferal) {
      completeRegistration = [
        {
          name: this.props.t("depositswithdraws"),
          pages: [
            { page: "deposits", name: this.props.t("deposits") },
            { page: "withdraw", name: this.props.t("withdraws") },
          ],
        },
        {
          name: "Orderbook",
          pages: [
            { page: "orderbook", name: this.props.t("buysell") },
            { page: "tx_history", name: this.props.t("historic") },
          ],
        },
      ]
    } else {
      completeRegistration = [];
    };

    return [
      ...completeRegistration,

      {
        name: this.props.t("safety"),
        pages: [
          { page: "kyc", name: "KYC" },
          { page: "twofa", name: this.props.t("2FAactivation") },
        ],
      },
      { page: "support", name: this.props.t("support") },
      { page: "profile", name: this.props.t("profile") },
    ];
  };


  componentDidMount = () => {

    this.setState({
      menu: this.userPages(this.context.loggedInUser, this.context.hasInvestmentPermission, this.context.hasReferalPermission).map((v) => true),
    });

    this.userPages(this.context.loggedInUser).forEach((menu, index) => {
      if (!menu.page) {
        menu.pages.forEach((subMenu) => {
          if (subMenu.page === this.props.page) {
            menu = this.state.menu;
            menu[index] = true;
            this.setState({ menu });
          }
        });
      }
    });

    api.get("profile").then((req) => {
      if (!req.error) {
        let name = req.data.name.split(" ").slice(0, 2).join(" ");
        let photoId = req.data.photoId;
        this.context.setPhotoId(photoId);
        let type = this.props.t('basic');
        if (req.data.phoneConfirm) {
          type = "Trader";
          if (req.data.kycStatus === "3") {
            type = "Trader";
          }
        }
        this.setState({
          name: name,
          type: type,
        });
      }
    });
  };

  toggleMenu = (index) => {
    let menu = this.state.menu;
    menu[index] = !menu[index];
    this.setState({ menu });
  };

  goTo = (path) => {
    this.props.navigate(path);
  };

  prepareValue = (value) => {
    return String(value).replace(".", ",");
  }

  brlToBitcoin = (value) => {
    const btcprice = this.context.prices.btc;
    const bitValue = Number(value / btcprice).toFixed(6);
    const finalNumber = this.prepareValue(bitValue);
    return finalNumber;
  }

  findAllInvestmentsValue = () => {
    if (this.context.allInvestments?.length === 0 || this.context?.allInvestments === null || this.context?.allInvestments === undefined) {
      return 0
    } else {

      const totalAmount2 = this.context.allInvestments?.reduce((acc, investment) => {
        if (investment.status === 'active' && investment?.dailyReports?.length === 0) {
          return acc + Number(investment.currentAmount);
        }
        if (investment.status === 'active' && investment?.dailyReports?.length > 0) {
          return acc + Number(investment?.dailyReports[investment?.dailyReports?.length - 1]?.finalAmount);
        }
        return acc;
      }, 0);

      return totalAmount2;


      // const totalAmount = this.context.allInvestments?.reduce((acc, investment) => acc + Number(investment.dailyReports[investment.dailyReports.length - 1].finalAmount), 0);

      // console.log({totalAmount2})
      // console.log({totalAmount})

      // const totalAmount = this.context.allInvestments?.reduce((acc, investment) => acc + Number(investment.currentAmount), 0);
      // console.log({totalAmount});
      // return totalAmount;
    }
  }

  getBalances = async (userId) => {
    api.get(`balances?filter[where][usersid]=${userId}`).then((req) => {
      if (req.error) {
        toast.error(this.props.t('error.reloadbalance'));
        this.setState({ reloading: false })
      } else {
        this.context.setBalances(req.data);
        console.log('balances', req);
        this.setState({ reloading: false })
      }
    });
  };

  getAllInvestments = async (userId) => {
    api.get(`users/investments/${userId}`).then((req) => {
      console.log(req);
      if (req.error) {
        this.context.setAllInvestments([]);
        toast.error(this.props.t('error.reloadinvestments'));
        this.setState({ reloadingInvestments: false })
      } if (req.data.error === "Investment not found") {
        this.context.setAllInvestments([]);
        this.setState({ reloadingInvestments: false })
      } else {
        this.context.setAllInvestments(req.data);
        console.log('investments', req.data);
        this.setState({ reloadingInvestments: false })
      }
    });
  };

  reloadItems = async (e) => {
    const { name } = e.target;
    const userId = this.context.loggedInUser.id;
    if (name === "balances") {
      this.setState({ reloading: true })
      this.getBalances(userId);
    }
    if (name === "allInvestments") {
      this.setState({ reloadingInvestments: true })
      this.getAllInvestments(userId);
    }
  };

  // returnBalance = (currency, locked = false) => {
  //   let currentBalance = this.context.balances.find(
  //     (el) => el.currency === 'BRL'
  //   );

  //   if (currentBalance) {
  //     if (currency === "BRL" && locked === false) {
  //       return `R$ ${formatBRL(currentBalance.currentAmount?.toFixed(2))}`;
  //     }

  //     if (currency === "BRL" && locked === true) {
  //       return `R$ ${this.prepareValue(currentBalance.lockedAmount?.toFixed(2))}`;
  //     }

  //     if (currency === "BTC" && locked === false) {
  //       return `${this.brlToBitcoin(currentBalance.currentAmount) + " BTC"} `;
  //     }

  //     if (currency === "BTC" && locked === true) {
  //       return `${this.brlToBitcoin(currentBalance.lockedAmount) + " BTC"} `;
  //     }
  //   }

  //   return `0 ${currency === "BRL" ? "BRL" : currency}`;
  // };

  // returnCurrentInvestment = () => {
  //   let context = this.context.trade;

  //   let currency = context?.currency;

  //   if (!context || !context?.currentAmount || !currency) {
  //     return `-`;
  //   }

  //   return `${this.prepareValue(Number(context?.currentAmount/this.context.prices.btc).toFixed(6))} ${currency}`;
  // };

  render() {
    return (
      <Container
        style={{
          width: 220,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          display: "grid",
          paddingTop: 5,
          paddingBottom: 20,
        }}
        fluid
      >
        <span className="text-mid2 text2 bold pl-1">{this.state.name}</span>
        <Image
          style={{
            margin: "auto",
            width: 60,
            height: 60,
          }}
          src={getProfilePhoto(this.context.photoId)}
          roundedCircle
        />
        <Image
          style={{
            marginLeft: "60%",
            marginTop: -15,
            width: 20,

            height: 20,
            cursor: "pointer",
          }}
          onClick={() => this.goTo("/panel/profile")}
          src="/images/photo.png"
        />
        <Row
          className="m-0"
          style={{
            justifyContent: "space-between",
          }}
        >
          <span className="text-mid2 bold text1" style={{ color: "#ceba85" }}>
            {this.state.type}

            {this.context.loggedInUser?.kycStatus !== "none" &&
              ` - KYC nível ${this.context.loggedInUser?.kycStatus}`}
          </span>
          <div
            className="container-notifications"
            onClick={() => this.goTo("/panel/notifications")}
          >
            <p className="count-notifications">
              {this.context.countNotifications > 99
                ? "99+"
                : this.context.countNotifications}
            </p>
          </div>
        </Row>
        <div style={{ width: 220, height: 2 }}></div>


        {/* Logged User */}

        {this.context.loggedInUser?.kycStatus !== "none" && (
          <div
            style={{
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: "#50504E",
                width: 220,
                height: 26,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <span className="text-mid bold text1 ml-3 mr-auto">
                {this.props.t('wallets')}
              </span>
              <div className="btn-menu text-mid">
                <Link to="/panel/send">
                  <i className="fas fa-long-arrow-alt-up text1 mr-1"></i>
                  <span className="text0 mr-1">{this.props.t('sendb')}</span>
                </Link>
              </div>
              <div className="btn-menu text-mid">
                <Link to="/panel/receive">
                  <i className="fas fa-long-arrow-alt-down text1 mr-1"></i>
                  <span className="text0 mr-1">{this.props.t('receive')}</span>
                </Link>
              </div>
            </div>

            {this.context?.balances?.map((balance, index) => (
                <div key={`balance-${index}`}>
                  <SideBarItem className="wallets-bar" key={`carteira-${index}`}>
                    {balance.currency === "BRL" ? (<span className="text-mid bold text1 ml-3 mr-auto">
                      {this.props.t('sbalance')} {balance.currency}
                    </span>)
                      : (<span className="text-mid bold text1 ml-3 mr-auto">
                        {this.props.t('wallet')} {balance.currency}
                      </span>)}
                    <div
                      onClick={(event) => {
                        this.reloadItems(event);
                      }
                      }
                      className="btn-menu d-flex ml-3 text-mid bold"
                      style={{ alignItems: "center", fontWeight: "400" }}
                      name="balances"
                    >
                      <img
                        className="mr-1"
                        alt="bitcoin icon"
                        src="/images/refresh-button.svg"
                        style={{ width: "16px", padding: "3px" }}
                        name="balances"
                      />
                    </div>
                  </SideBarItem>
                  <SideBarItem key={`carteira-${index + 1}`}>
                    <span className="bold ml-3 mr-auto">{this.props.t('sbalance')}</span>
                    {this.state.reloading && (
                      <span className="mr-3">
                        <Spinner animation="border" variant="secondary" style={{ maxWidth: "16px", maxHeight: "16px" }} />
                      </span>
                    )}
                    {this.state.reloading === false && (
                      (balance.currency === "BRL") ? (
                        <span className="mr-3">
                          {numberFormatBRL(balance.currentAmount - balance.lockedAmount)}
                        </span>
                      )
                        : (
                          <span className="mr-3">
                            {balance.currency === "BTC" ? Number(balance.currentAmount - balance.lockedAmount).toFixed(6) : Number(balance.currentAmount - balance.lockedAmount).toFixed(3)} {balance.currency}
                          </span>
                        )
                    )}
                  </SideBarItem>
                  {balance.currency === "BTC" && (
                    <SideBarItem key={`carteira-${index + 2}`}>
                      <span className="bold ml-3 mr-auto">{this.props.t('refference')}</span>
                      {this.state.reloading ? (
                        <span className="mr-3">
                          <Spinner animation="border" variant="secondary" style={{ maxWidth: "16px", maxHeight: "16px" }} />
                        </span>
                      ) : (
                        <span className="mr-3">
                          {numberFormatBRL((balance.currentAmount - balance.lockedAmount) * (this.context.btcPrice))}
                        </span>
                      )}
                    </SideBarItem>
                  )}
                  {balance.currency === "USDT" && ( 
                  <SideBarItem key={`carteira-${index + 3}`}>
                    <span className="bold ml-3 mr-auto">{this.props.t('refference')}</span>
                    { this.state.reloading ? (
                      <span className="mr-3">
                        <Spinner animation="border" variant="secondary" style={{maxWidth: "16px", maxHeight: "16px"}}/>
                      </span>
                    ) : (
                      <span className="mr-3">
                        { numberFormatBRL((balance.currentAmount - balance.lockedAmount) * (this.context.usdtPrice)) }
                      </span>
                    )}
                  </SideBarItem>
                )}
                </div>
              ))}
            <SideBarItem className="wallets-bar">
              <span className="text-mid bold text1 ml-3 mr-auto">
                {this.props.t('investments')}
              </span>
              <div
                name="allInvestments"
                onClick={(e) => {
                  this.reloadItems(e);
                }}
                className="btn-menu d-flex ml-3 text-mid bold"
                style={{ alignItems: "center", fontWeight: "400" }}
              >
                <img
                  className="mr-1"
                  alt="bitcoin icon"
                  src="/images/refresh-button.svg"
                  style={{ width: "16px", padding: "3px" }}
                  name="allInvestments"
                />
                {/* <span className="text0 mr-1">Atualizar</span> */}
              </div>
            </SideBarItem>
            <SideBarItem>
              <span className="bold ml-3 mr-auto">Total</span>
              {this.state.reloadingInvestments ? (
                <span className="mr-3">
                  <Spinner animation="border" variant="secondary" style={{ maxWidth: "16px", maxHeight: "16px" }} />
                </span>
              ) : (
                <span className="mr-3">
                  {Number((this.findAllInvestmentsValue()) / (this.context.btcPrice)).toFixed(6)} BTC
                </span>
              )}
            </SideBarItem>
            <SideBarItem>
              <span className="bold ml-3 mr-auto">{this.props.t('refference')}</span>
              {this.state.reloadingInvestments ? (
                <span className="mr-3">
                  <Spinner animation="border" variant="secondary" style={{ maxWidth: "16px", maxHeight: "16px" }} />
                </span>
              ) : (
                <span className="mr-3">
                  {numberFormatBRL(this.findAllInvestmentsValue())}
                </span>
              )}
            </SideBarItem>

            <SideBarItem>
              <div
                onClick={() => {
                  this.context.setModalBuyBTC(true);
                }}
                className="btn-menu d-flex ml-3 text-mid bold"
              >
                <img
                  className="mr-1"
                  alt="bitcoin icon"
                  src="/images/btc2.svg"
                  style={{ width: "0.6rem" }}
                />
                <span className="text0 mr-1">{this.props.t('buy')} Bitcoin</span>
              </div>
            </SideBarItem>

            <div
              style={{
                backgroundColor: "#000",
                width: "320%",
                height: 2,
                opacity: 0.5,
                marginTop: 5,
                marginBottom: 5,
              }}
            ></div>
          </div>
        )}

        <div>
          <div
            style={{
              backgroundColor: "#50504E",
              width: "100%",
              height: 26,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={this.toggleMenu}
          >
            <span className="text-mid bold text1 ml-3 mr-auto">Menu</span>
            <i className="fas fa-caret-down text1 text-large mr-3"></i>
          </div>
        </div>
        <div>
          {this.userPages(this.context.loggedInUser, this.context.hasInvestmentPermission, this.context.hasReferalPermission).map((menu, index) =>
            menu.page ? (
              <SideBarItem
                key={`menu-${index}`}
                onClick={() => this.goTo("/panel/" + menu.page)}
                selected={this.props.page}
                page={menu.page}
                bg={index % 2 !== 0 ? 1 : 0}
              >
                <span className="bold ml-3 mr-auto">{menu.name}</span>
              </SideBarItem>
            ) : (
              <div key={`menu-${index}`}>
                <SideBarItem
                  onClick={() => this.toggleMenu(index)}
                  bg={index % 2 !== 0 ? 1 : 0}
                >
                  <span className="bold ml-3 mr-auto">{menu.name}</span>
                  <i className="fas fa-caret-down text-large mr-3"></i>
                </SideBarItem>
                <Collapse in={this.state.menu[index]}>
                  <div>
                    {menu.pages.map((subMenu) => (
                      <SideBarItem
                        key={`subMenu-${subMenu.page}`}
                        onClick={() => this.goTo("/panel/" + subMenu.page)}
                        selected={this.props.page}
                        page={subMenu.page}
                      >
                        <i className="fas fa-angle-right ml-3 mr-1"></i>
                        <span className="bold">{subMenu.name}</span>
                      </SideBarItem>
                    ))}
                  </div>
                </Collapse>
              </div>
            )
          )}
          <SideBarItem
            onClick={() => this.goTo("/tarifas")}
            page="tarifas"
            bg={
              this.userPages(this.context.loggedInUser, this.context.hasInvestmentPermission, this.context.hasReferalPermission).length % 2 !== 0
                ? 1
                : undefined
            }
          >
            <span className="bold ml-3 mr-auto">{this.props.t('taxes')}</span>
          </SideBarItem>
          <SideBarItem
            onClick={() => this.goTo("/panel/logout")}
            selected={this.props.page}
            page="logout"
            bg={
              this.userPages(this.context.loggedInUser, this.context.hasInvestmentPermission, this.context.hasReferalPermission).length % 2 !== 0
                ? undefined
                : 1
            }
          >
            <span className="bold ml-3 mr-auto">{this.props.t('signout')}</span>
          </SideBarItem>
        </div>
      </Container>
    );
  }
}
const TranslatedSideBar = withTranslation()(SideBar);
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => <TranslatedSideBar {...props} navigate={useNavigate()} />;
