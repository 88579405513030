import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import api, { microservice } from "../../../../utils/api";
import { AppContext } from "../../../../context/AppContext";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export function formatPercentage(percentageValue) {
  var value = percentageValue.replace(",", ".");
  value = percentageValue.replace(/[^0-9.,]/g, "");
  if (value.includes("%")) {
    value = value.replace("%", "");
  }
  if (value.includes('.')) {
    value = value.slice(0, value.indexOf('.') + 3);
  } else {
    value = value.slice(0, 3);
    if (value > 100) {
      value = 100;
    }
  }
  return value;
}

function Create() {
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const {
    showModalCreateProfitabily,
    setShowModalCreateProfitabily,
    availablesMonthsOfContract,
  } = useContext(AppContext);

  const rate = useRef(null);
  const { id } = useParams();
  const investmentId = id;

  const [newProfitability, setNewProfitability] = useState({
    month: 0,
    rate: 0,
  });

  const handleCloseCreateNewProfitability = () => {
    setShowModalCreateProfitabily(!showModalCreateProfitabily);
  };

  const submit = (event) => {
    setLoadingChangeStatus(true);
    event.preventDefault();
    // api.post(`investments/${investmentId}/profitabilities`, {
    //   month: Number(newProfitability.month),
    //   rate: Number(newProfitability.rate),
    // })
    // {
    //   "investmentId": "648377049b29295c223b02b7",
    //   "date": "2023-05-07T23:59:59.000+00:00",
    //   "profitPercentage": 0.01
    // }
    api.post(`profits`, {
      investmentId: investmentId,
      date: dayjs(newProfitability.month),
      profitPercentage: ((Number(newProfitability.rate)) / 100),
    })
      .then((response) => {
        if (response.error) {
          setLoadingChangeStatus(false);
          return;
        }
        setLoadingChangeStatus(false);
        toast.success("Rentabilidade criada com sucesso.", {
          position: "top-right",
          theme: "colored",
        });
        window.location.reload();
        handleCloseCreateNewProfitability();
      }).catch(() => {
      })
  };

  useEffect(() => {
    setNewProfitability((prevState) => ({
      ...prevState,
      month: availablesMonthsOfContract[0],
    }));
  }, []);

  return (
    <Modal
      show={showModalCreateProfitabily}
      onHide={handleCloseCreateNewProfitability}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="w-100 ml-3 text-center"
          style={{ textDecoration: "underline", textTransform: "uppercase", color: "#d3b25b" }}
        >
          Nova Rentabilidade
        </Modal.Title>
      </Modal.Header>
      <Form className="w-100" onSubmit={submit}>
        <Modal.Body className="w-100 pb-0 pt-0">
          <Row
            className="mb-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Row className="mb-1 w-100">
              <Form.Group as={Col}>
                <Form.Label>Valor da rentabilidade</Form.Label>
                <div className="d-flex align-items-center">
                  <input ref={rate} className="form-control input append" name="rate" onChange={(e) => {
                    e.persist();
                    const value = formatPercentage(e.target.value);
                    rate.current.value = value;
                    if (value.length > 0 && value !== "0" && value !== 0) {
                      setNewProfitability((prevState) => ({
                        ...prevState,
                        rate: Number(value),
                      }))
                    }
                  }} required />

                  <div className="input-group-append append-from-input">
                    <span className="input-group-text input append-from-input">
                      %
                    </span>
                  </div>
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-1 w-100">
            <Form.Group as={Col}>
                <Form.Label>Selecione a data da Rentabilidade</Form.Label>
                <input
                  type="date"
                  // value={newProfitability.month}
                  onChange={(e) => {
                    e.persist();
                    setNewProfitability((prevState) => ({
                      ...prevState,
                      month: e.target.value,
                    }));
                  }}
                  className="form-control input"
                  style={{ width: "100%" }}
                />
              </Form.Group>
              {/* <Form.Group as={Col}>
                <Form.Label>Selecione o mês da rentabilidade</Form.Label>
                <select
                  value={newProfitability.month}
                  onChange={(e) => {
                    e.persist();
                    setNewProfitability((prevState) => ({
                      ...prevState,
                      month: e.target.value,
                    }));
                  }}
                  className="form-control input"
                  style={{ width: "100%" }}
                >
                  {availablesMonthsOfContract.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </Form.Group> */}
            </Row>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loadingChangeStatus ? (
            <div className="text-center">
              <Spinner
                variant="secondary"
                animation="border"
                role="status"
              ></Spinner>
            </div>
          ) : (
            <>
              <Button variant="primary" type="submit">
                Confirmar
              </Button>
              <Button
                onClick={handleCloseCreateNewProfitability}
                variant="primary"
              >
                Cancelar
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default Create;
