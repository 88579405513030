import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Container, Image, Row, Collapse } from "react-bootstrap";
import api from "../utils/api";
import { getProfilePhoto } from "../utils/helper";
import { adminPages } from "../utils/menu";
import { AppContext } from "../context/AppContext";
import { formatMoney } from "../utils/helper";
import { numberFormatBRL } from "./InvestmentId";

const SideBarItem = React.forwardRef(
  ({ activeClassName, activeStyle, ...props }, ref) => {
    if (!props.page) {
      return (
        <div
          ref={ref}
          className="text-mid"
          style={{
            backgroundColor:
              props.selected === props.page && props.selected !== undefined
                ? "#CEBA85"
                : props.bg
                ? "#F1F1F1"
                : "#E2E2E2",
            width: "100%",
            height: 26,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            color: "black",
            cursor: "pointer",
          }}
          onClick={props.onClick}
          {...props}
        >
          {props.children}
        </div>
      );
    }
    return (
      <NavLink
        ref={ref}
        to={"/panel/" + props.page}
        className="text-mid text-dark"
        style={({ isActive }) => ({
          backgroundColor: isActive
            ? "#CEBA85"
            : props.bg
            ? "#F1F1F1"
            : "#E2E2E2",
          width: "100%",
          height: 26,
          display: "flex",
          alignItems: "center",
          color: isActive ? "#000000 !important" : "#343a40 !important",
          cursor: "pointer",
          textDecoration: "none",
        })}
        onClick={props.onClick}
        {...props}
      >
        {props.children}
      </NavLink>
    );
  }
);

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    let state = {
      menu: adminPages.map((v) => true),
      menuInvestments: true,
      menuWithdraw: true,
      menuSecurity: true,
      name: "",
      type: "",
      photoId: "",
      bcxBalance: { BRL: 0, BTC: 0, USDT: 0, TAX: 0 },
    };
    this.state = state;
  }

  static contextType = AppContext;

  componentDidMount = () => {
    adminPages.forEach((menu, index) => {
      if (!menu.page) {
        menu.pages.forEach((subMenu) => {
          if (subMenu.page === this.props.page) {
            menu = this.state.menu;
            menu[index] = true;
            this.setState({ menu });
          }
        });
      }
    });
    api.get("profile").then((req) => {
      if (!req.error) {
        let name = req.data.name.split(" ").slice(0, 2).join(" ");
        let photoId = req.data.photoId;
        let type = "";
        if (req.data.roles.name !== "user") {
          type = req.data.roles.name.toLocaleUpperCase();
        }
        this.setState({
          name: name,
          photoId: photoId,
          type: type,
        });
      }
    });
    this.getBCXBalance();
  };

  getBCXBalance = async () => {
    const usersId = process.env.REACT_APP_MASTER_USER_ID;
    const balances = await api.get(
      `balances?filter[where][usersid]=${usersId}`
    );
    // const nodeBalance = await api.get(`balances/node`);
    // console.log('nodeBalance >>', nodeBalance);

    const BRL = balances.data.find((balance) => balance.currency === "BRL");
    // const BTC = nodeBalance.data.balance;
    const BTC = balances.data.find((balance) => balance.currency === "BTC");
    const USDT = balances.data.find((balance) => balance.currency === "USDT");

    let taxBalance = await api.get(
      `balances?filter[where][usersid]=${process.env.REACT_APP_MASTER_USER_ID}`
    );
    console.log('taxBalance >>', taxBalance);

    // const TAX = taxBalance.data.find((balance) => balance.currency === "BTC");
    const BITCOIN = taxBalance.data.find((balance) => balance.currency === "BTC");

    const REAIS = taxBalance.data.find((balance) => balance.currency === "BRL");

    // this.setState({ bcxBalance: { BRL: Number(BRL.currentAmount).toFixed(2), BTC: Number(BTC ?? 0).toFixed(6), TAX: Number(TAX.currentAmount).toFixed(6) } });
    this.setState({ bcxBalance: { BRL: Number(REAIS.currentAmount).toFixed(2), BTC: Number(BITCOIN?.currentAmount).toFixed(8), USDT: Number(USDT?.currentAmount).toFixed(3) } });
  };

  toggleMenu = (index) => {
    let menu = this.state.menu;
    menu[index] = !menu[index];
    this.setState({ menu });
  };

  goTo = (path) => {
    this.props.navigate(path);
  };

  returnCountPendingStatus = (type) => {

    if (type == "Depósitos") {
      return this.context.countPendingStatus?.deposits?.count;
    }

    if (type == "Saques") {
      return this.context.countPendingStatus?.withdrawals?.count;
    }

    if (type == "Aportes") {
      return this.context.countPendingStatus?.trade?.count;
    }

    if (type == "Saques Investimentos") {
      return this.context.countPendingStatus?.trade;
    }

    return null;
  };

  render() {
    return (
      <Container
        style={{
          width: 260,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          display: "grid",
          paddingTop: 5,
          paddingBottom: 20,
        }}
        fluid
      >
        <span className="text-mid2 text2 bold pl-1">{this.state.name}</span>
        <Image
          style={{
            margin: "auto",
            width: 60,
            height: 60,
          }}
          src={getProfilePhoto(this.state.photoId)}
          roundedCircle
        />
        <Image
          style={{
            marginLeft: "60%",
            marginTop: -15,
            width: 20,
            height: 20,
            cursor: "pointer",
          }}
          src="/images/photo.png"
          onClick={() => this.goTo("/panel/profile")}
        />
        <Row
          className="m-0"
          style={{
            justifyContent: "space-between",
          }}
        >
          <span className="text-mid bold text1">
            {this.state.type} - Nível{" "}
            {this.context?.loggedInUser?.permission?.level}
          </span>
          <div
            className="container-notifications"
            onClick={() => this.goTo("/panel/admin/notifications")}
          >
            <p className="count-notifications">
              {this.context.countNotifications > 99
                ? "99+"
                : this.context.countNotifications}
            </p>
          </div>
        </Row>
        <div
          style={{
            backgroundColor: "#000",
            width: 220,
            height: 1,
            opacity: 0.5,
            marginBottom: 5,
          }}
        ></div>
        <div>
          <div
            style={{
              backgroundColor: "#50504E",
              width: "100%",
              height: 26,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <span className="text-mid bold text1 ml-3 mr-auto">Carteiras</span>
            {/* <div className="btn-menu text-mid">
              <i className="fas fa-long-arrow-alt-up text1 mr-1"></i>
              <span className="text0 mr-1">Enviar</span>
            </div>
            <div className="btn-menu text-mid">
              <i className="fas fa-long-arrow-alt-down text1 mr-1"></i>
              <span className="text0 mr-1">Receber</span>
            </div> */}
          </div>
          <SideBarItem className="wallets-bar">
            <span className="text-mid bold text1 ml-3 mr-auto">
              Carteira BTC
            </span>
          </SideBarItem>
          <SideBarItem>
            <span className="bold ml-3 mr-auto">Saldo</span>
            <span className="ml-1 mr-1">
              {this.state.bcxBalance?.BTC} BTC
            </span>
          </SideBarItem>
          <SideBarItem>
          <span className="bold ml-3 mr-auto">Referência</span>
            <span className="ml-1 mr-1">
              { numberFormatBRL(Number(this.state.bcxBalance?.BTC) * Number(this.context.btcPrice)) }
            </span>
          </SideBarItem>
          <SideBarItem className="wallets-bar">
            <span className="text-mid bold text1 ml-3 mr-auto">
              Carteira USDT
            </span>
          </SideBarItem>
          <SideBarItem>
            <span className="bold ml-3 mr-auto">Saldo</span>
            <span className="ml-1 mr-1">
              {this.state.bcxBalance?.USDT} USDT
            </span>
          </SideBarItem>
          <SideBarItem>
          <span className="bold ml-3 mr-auto">Referência</span>
            <span className="ml-1 mr-1">
              { numberFormatBRL(Number(this.state.bcxBalance?.USDT) * Number(this.context.usdtPrice)) }
            </span>
          </SideBarItem>
          <SideBarItem className="wallets-bar">
            <span className="text-mid bold text1 ml-3 mr-auto">
              Saldo BRL
            </span>
          </SideBarItem>
          <SideBarItem>
            <span className="bold ml-3 mr-auto">Saldo</span>
            <span className="ml-1 mr-1">{numberFormatBRL(this.state.bcxBalance?.BRL)}</span>
          </SideBarItem>
          {/* <SideBarItem>
            <span className="bold ml-3 mr-auto">Balanço Taxas - BTC</span>
            <span className="ml-1 mr-1">{this.state.bcxBalance?.TAX} BTC</span>
          </SideBarItem> */}
          {/* <SideBarItem>
            <span className="ml-3 mr-auto">Valor investido</span>
            <span className="mr-3">R$ 25.000,00</span>
          </SideBarItem>
          <SideBarItem>
            <span className="ml-3 mr-auto">Em ordens</span>
            <span className="mr-3">R$ 25.000,00</span>
          </SideBarItem> */}

          <div
            style={{
              backgroundColor: "#000",
              width: 220,
              height: 1,
              opacity: 0.5,
              marginTop: 5,
              marginBottom: 5,
            }}
          ></div>
        </div>
        <div
          style={{
            backgroundColor: "#50504E",
            width: "100%",
            height: 26,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={this.toggleMenu}
        >
          <span className="text-mid bold text1 ml-3 mr-auto">Menu</span>
          {/* <i className="fas fa-caret-down text1 text-large mr-3"></i> */}
        </div>
        <Collapse in={true}>
          <div>
            {adminPages.map((page, index) =>
              page.page ? (
                <SideBarItem
                  key={`page-${page.page}`}
                  onClick={() => this.goTo("/panel/" + page.page)}
                  selected={this.props.page}
                  page={page.page}
                  bg={index % 2 !== 0 ? 1 : 0}
                >
                  <span className="bold ml-3 mr-auto">{page.name}</span>
                </SideBarItem>
              ) : (
                <div key={`page-${index}`}>
                  <SideBarItem
                    onClick={() => this.toggleMenu(index)}
                    bg={index % 2 !== 0 ? 1 : 0}
                  >
                    <span className="bold ml-3 mr-auto">{page.name}</span>
                    <i className="fas fa-caret-down text-large mr-3"></i>
                  </SideBarItem>
                  <Collapse in={this.state.menu[index]}>
                    <div>
                      {page.pages.map((subMenu) => (
                        <SideBarItem
                          key={`subMenu-${subMenu.page}`}
                          onClick={() => this.goTo("/panel/" + subMenu.page)}
                          selected={this.props.page}
                          page={subMenu.page}
                          bg={index % 2 !== 0 ? 1 : 0}
                        >
                          <i className="fas fa-angle-right ml-3 mr-1"></i>
                          <div
                            style={
                              subMenu.name !== "Usuários Administradores" &&
                              subMenu.name !== "Clientes"
                                ? {
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    columnGap: "10px",
                                  }
                                : {}
                            }
                          >
                            <span
                              className="bold"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {subMenu.name}
                            </span>
                            <span className="bold pending-count-status">
                              {this.returnCountPendingStatus(subMenu.name)}
                            </span>
                          </div>
                        </SideBarItem>
                      ))}
                    </div>
                  </Collapse>
                </div>
              )
            )}
            {/* <SideBarItem
              onClick={() => this.goTo("/panel/admin/twofactor")}
              bg={adminPages.length % 2 !== 0 ? 1 : 0}
            >
              <span className="bold ml-3 mr-auto">2FA</span>
            </SideBarItem> */}
            <SideBarItem
              onClick={() => this.goTo("/panel/logout")}
              bg={adminPages.length % 2 !== 0 ? 1 : 0}
            >
              <span className="bold ml-3 mr-auto">Sair</span>
            </SideBarItem>
            
          </div>
        </Collapse>
      </Container>
    );
  }
}

export default (props) => <SideBar {...props} navigate={useNavigate()} />;
