import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import Panel from "../../components/Panel";
import { toast } from "react-toastify";
import api,{ microservice } from "../../utils/api";
import { LinkContainer } from "react-router-bootstrap";
import dayjs from "dayjs";

export function formatPercentage(percentageValue) {
  var value = percentageValue.replace(",", ".");
  value = percentageValue.replace(/[^0-9.,]/g, "");
  if (value.includes("%")) {
    value = value.replace("%", "");
  }
  if (value.includes('.')) {
    value = value.slice(0, value.indexOf('.') + 3);
  } else {
    value = value.slice(0, 3);
    if (value > 100) {
      value = 100;
    }
  }
  return value;
}

function AdminNewProfitability(props) {

  const [selectedOption, setSelectedOption] = useState('standard');
  const [profitState, setProfitState] = useState(0);
  const [initialAmountState, setInitialAmountState] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  }

  const handleProfit = (event) => {
    setProfitState(event.target.value);
  }

  // const handleInitialAmount = (event) => {
  //   setInitialAmountState(event.target.value);
  // }

  const handleSubmit = (event) => {
    event.preventDefault();
    submit();
  }

  const submit = async () => {
    const res = await api.post(`modalities/profits`, {
      "modality": selectedOption,
      "date": dayjs().format("YYYY-MM-DD"),
      "profitPercentage": (profitState / 100),
    });

    if (!res.error) {
      toast.success("Nova rentabilidade criada com sucesso!", {
        position: "top-right",
        theme: "colored",
      });
        navigate("/panel/admin/");
    } else {
      console.log(res);
      toast.error("Houve um problema e a nova rentabilidade não foi registrado", {
        position: "top-right",
        theme: "colored",
      });
    }
  };

    return (
      <Panel
        load={false}
        navigate={props.navigate}
        page="admin/cadastro-rentabilidade"
        title="Cadastro de Rentabilidade"
        admin={true}
      >

      <div className="new-inv-div">
        <div className="new-inv-user">
          Cadastro de Rentabilidade
        </div>
        <div style={{ marginTop: "20px", color: "black", textAlign: "center", maxWidth: "800px"}}>
          <p>Realize o cadastro da rentabilidade diária no formulário abaixo e clique em "Criar" para adicionar a rentabilidade automaticamente a todos os investimentos que possuírem a mesma modalidade/tipo de investimento informado.</p>
        </div>

        <Form className="w-100 new-inv-forms" onSubmit={ handleSubmit }>
          <Form.Group className="mt-4 mb-2">
            <br></br>
            <Form.Label style={{ fontWeight : "bold", color: "black", textAlign: "center" }}>
              Tipo de Investimento
            </Form.Label>
            <div className="new-inv-input-group">
              <select name="select" className="new-inv-select-input" value={ selectedOption } onChange={ handleSelectChange }>
                <option value="standard">
                  Standard
                </option>
                <option value="alto valor">
                  Alto Valor
                </option>
                <option value="high performance">
                  High Performance
                </option>
              </select>
            </div>
            <br></br>
            <Form.Label style={{ fontWeight : "bold", color: "black", textAlign: "center" }}>
              Rentabilidade Diária (em %)
            </Form.Label>
            <div className="input-group">
              <input
                className="input form-control"
                type="number" 
                name="profit"
                onChange={ handleProfit }
                max="100"
                value={ profitState }
                style={{ width :"467px", height:  "38px",  borderColor:  "#B4A680 !important"}}
                />
            </div>
            <br></br>
            </Form.Group>
            <div className="new-inv-btn-div">
              <Button variant="primary" type="submit" className="new-inv-btns">
                Criar
              </Button>
              <LinkContainer to={"/panel/admin/"}>
                <Button variant="primary" className="new-inv-btns cancel-btn">
                  Cancelar
                </Button>
              </LinkContainer>
            </div>
        </Form>
      </div>
      <br></br>
      <div>
        <div className="kyc-policy">
        <h4 className="kyc-policy-title">Histórico de Rentabilidades</h4>
        <p>Consulte o histórico de Rentabilidades inseridas na plataforma clicando no botão abaixo: </p>
        <Link to="/panel/admin/historico-rentabilidades">
          <Button>Ver Histórico</Button> 
        </Link>
        </div>
      </div>
      </Panel>
    );
}

export default AdminNewProfitability;
