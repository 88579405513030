import dayjs from "dayjs";
import api from "./api";

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const getProfilePhoto = (fileId) => {
  if (fileId) {
    return `${api.getURL()}files/${fileId}/download`;
  } else {
    return `/images/profile.png`;
  }
};

export const formatStatusHelper = (status) => {
  if (status == "pending") {
    return "Pendente";
  }

  if (status == "active") {
    return "Ativo";
  }

  if (status == "finished") {
    return "Encerrado";
  }

  if (status == "paused") {
    return "Pausado";
  }
};

export const disableAction = (nivel, kyc, money, superAdmin) => {
  if (nivel == 1) {
    return true;
  }

  if (nivel == 2 && superAdmin == false && (kyc == true || money == true)) {
    return false;
  }

  if (nivel == 3 && superAdmin == false) {
    return false;
  }

  if (nivel == 4) {
    return false;
  }

  return true;
};

export function formatDate(date) {
  return dayjs(date).format("DD/MM/YYYY");
}

export function formatDateDay(date) {
  return dayjs(date).date();
}
